<template>
  <h1>404</h1>
  <h2>Error</h2>
  <p>Esta URL no existe</p>
</template>

<script>
export default {
    name: 'Auditorio',
    created(){
      document.title = '404 - XXI Congreso Nacional Explora'
    }
}
</script>

<style>

</style>